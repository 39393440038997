import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Apartments, Roof, Content } from "page_components/gardens"

const Gardens = () => {
  const title = "Ogrody City Łobzów"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title="Ogrody" />
      <PageHeader title={title} />
      <Apartments />
      <Roof />
      <Content />
    </Layout>
  )
}

export default Gardens
