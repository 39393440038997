import "./styles.scss"

import React from "react"

const Roof = () => {
  return (
    <section className="gardens-roof">
      <div className="container">
        <div className="gardens-roof__header">
          <div className="row">
            <div className="col-lg-6">
              <h3>
                Wznieś się na najwyższy poziom i wybierz mieszkanie
                z&nbsp;ogrodem na dachu
              </h3>
            </div>
            <div className="col-lg-6">
              <p>
                Ekskluzywne apartamenty na najwyższych piętrach posiadają
                przestrzenie do zagospodarowania odpowiadające metrażowi
                mieszkania. Do ogrodu będziesz mieć dostęp tylko Ty,
                bezpośrednio z Twojego mieszkania. Dzięki przegrodzeniom i
                attykom Twój ogród będzie przestrzenią bezpieczną i w pełni
                funkcjonalną.
              </p>
            </div>
          </div>
        </div>
        <div className="gardens-roof__image d-flex justify-content-center">
          <img src={require("assets/images/gardens/roof-image.jpg").default} alt="" />
        </div>
      </div>
    </section>
  )
}

export default Roof
