import "./styles.scss"

import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import {
  useMotionTemplate,
  useScroll,
  useTransform,
  motion,
} from "framer-motion"

import ModalImage from "components/ModalImage"

const slider = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 750,
  slidesToShow: 2,
  slidesToScroll: 1,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const gallery = [
  require("assets/images/gardens/gallery-image-1.jpg").default,
  require("assets/images/gardens/gallery-image-2.jpg").default,
  require("assets/images/gardens/gallery-image-1.jpg").default,
  require("assets/images/gardens/gallery-image-2.jpg").default,
]

const Content = () => {
  const [modalImage, setModalImage] = useState(null)

  const decor1 = useRef(null)
  const decor2 = useRef(null)
  const decor3 = useRef(null)
  const decor4 = useRef(null)
  const decor5 = useRef(null)
  const decor6 = useRef(null)

  const offset = ["start end", "end end"]

  const { scrollYProgress: progress1 } = useScroll({
    target: decor1,
    offset,
  })

  const { scrollYProgress: progress2 } = useScroll({
    target: decor2,
    offset,
  })

  const { scrollYProgress: progress3 } = useScroll({
    target: decor3,
    offset,
  })

  const { scrollYProgress: progress4 } = useScroll({
    target: decor4,
    offset,
  })

  const { scrollYProgress: progress5 } = useScroll({
    target: decor5,
    offset,
  })

  const { scrollYProgress: progress6 } = useScroll({
    target: decor6,
    offset,
  })

  const timing = [0, 1]
  const timingHalf = [0, 0.5]

  const decor1_x = useTransform(progress1, timing, ["50%", "0%"])
  const decor1_y = useTransform(progress1, timing, ["12.5%", "0%"])
  const decor1_rotate = useTransform(progress1, timing, ["12deg", "0deg"])
  const decor1_opacity = useTransform(progress1, timingHalf, [0, 1])

  const decor2_x = useTransform(progress2, timing, ["-25%", "0%"])
  const decor2_y = useTransform(progress2, timing, ["-6.25%", "0%"])
  const decor2_rotate = useTransform(progress2, timing, ["-14deg", "0deg"])
  const decor2_opacity = useTransform(progress2, timingHalf, [0, 1])

  const decor3_x = useTransform(progress3, timing, ["-6.25%", "0%"])
  const decor3_opacity = useTransform(progress3, timingHalf, [0, 1])

  const decor4_x = useTransform(progress4, timing, ["-25%", "0%"])
  const decor4_rotate = useTransform(progress4, timing, ["-12deg", "0deg"])
  const decor4_opacity = useTransform(progress4, timingHalf, [0, 1])

  const decor5_x = useTransform(progress5, timing, ["50%", "0%"])
  const decor5_y = useTransform(progress5, timing, ["12.5%", "0%"])
  const decor5_rotate = useTransform(progress5, timing, ["12deg", "0deg"])
  const decor5_opacity = useTransform(progress5, timingHalf, [0, 1])

  const decor6_y = useTransform(progress6, timing, ["-25%", "0%"])
  const decor6_scale = useTransform(progress6, timing, [0.75, 1])
  const decor6_opacity = useTransform(progress6, timingHalf, [0, 1])

  return (
    <>
      <section className="gardens-content">
        <div className="container">
          <div className="gardens-content__wrapper">
            <h2>Zyskaj przestrzeń!</h2>
            <div className="row row--1 align-items-center">
              <div className="col-lg-6">
                <div className="gardens-content__description gardens-content__description--1">
                  <h3>Bliskość natury</h3>
                  <p>
                    Zaprojektuj ogród swoich marzeń i ciesz się naturą na
                    wyciągnięcie ręki! Przestrzeń na dachu pozwoli Ci stworzyć
                    prawdziwą zieloną oazę – miejsce do relaksu, spotkań z
                    bliskimi czy nawet uprawy własnych roślin. Nie musisz
                    wyjeżdżać za miasto, by poczuć harmonię i spokój w otoczeniu
                    zieleni.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="gardens-content__image gardens-content__image--1">
                  <div>
                    <img
                      src={
                        require("assets/images/gardens/content-image-1.jpg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row row--2">
              <div className="col-lg-6 offset-xxl-1 offset-xl-2 offset-lg-3">
                <div className="gardens-content__description gardens-content__description--2">
                  <h3>Przestrzeń dopasowana do ciebie</h3>
                  <p>
                    Twoje mieszkanie nie kończy się na czterech ścianach! Ogród
                    na dachu to dodatkowa przestrzeń, którą możesz urządzić
                    według własnych potrzeb. Strefa wypoczynkowa z wygodnymi
                    leżakami, miejsce do jogi czy kącik dla dzieci – wszystko
                    zależy od Twojej wyobraźni!
                  </p>
                </div>
              </div>
            </div>
            <div className="row row--3">
              <div className="col-lg-6">
                <div className="gardens-content__image gardens-content__image--2">
                  <div>
                    <img
                      src={
                        require("assets/images/gardens/content-image-2.jpg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="gardens-content__description gardens-content__description--3">
                  <h3>Ekologia i świeże powietrze</h3>
                  <p>
                    Zielone dachy to nie tylko estetyka, ale wspieranie
                    ekologii. Rośliny na dachu poprawiają jakość powietrza,
                    obniżają temperaturę w upalne dni i redukują hałas miejski.
                    Tworząc swoją zieloną przestrzeń, dbasz nie tylko o komfort,
                    ale i o środowisko.
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1 d-flex flex-column-reverse flex-lg-column">
                <div className="gardens-content__description gardens-content__description--4">
                  <h3>Panorama, która zachwyca</h3>
                  <p>
                    Poranna kawa z widokiem na tętniące życiem miasto? A może
                    wieczorny relaks przy zachodzie słońca? Ogród na dachu to
                    nie tylko miejsce odpoczynku, ale także niesamowita
                    sceneria, która sprawi, że każdy dzień nabierze wyjątkowego
                    charakteru.
                  </p>
                </div>
                <div className="gardens-content__image gardens-content__image--3">
                  <div>
                    <img
                      src={
                        require("assets/images/gardens/content-image-3.jpg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row row--4 align-items-center">
              <div className="col-lg-6">
                <div className="gardens-content__image gardens-content__image--4">
                  <div>
                    <img
                      src={
                        require("assets/images/gardens/content-image-4.jpg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="gardens-content__description gardens-content__description--5">
                  <h3>Prywatność na najwyższym poziomie</h3>
                  <p>
                    Na najwyższym piętrze zyskujesz spokój i dyskrecję, której
                    nie znajdziesz na tradycyjnych balkonach. Ciesz się pełną
                    swobodą, wiedząc, że nikt nie zakłóci Twojego wypoczynku.
                    Twój ogród na dachu to przestrzeń tylko dla Ciebie!
                  </p>
                </div>
              </div>
            </div>
            <div className="row row--5">
              <div className="col-lg-6 offset-xxl-1 offset-xl-2">
                <div className="gardens-content__description gardens-content__description--6">
                  <h3>Zainspiruj się!</h3>
                  <p>
                    Odkryj kreatywne sposoby na zielony dach! Od przytulnych,
                    pełnych kwiatów zakątków po nowoczesne przestrzenie do
                    relaksu i spotkań. Zobacz, jak funkcjonalność łączy się z
                    estetyką, tworząc wyjątkowe miejsca blisko natury, nawet w
                    sercu miasta.
                  </p>
                </div>
              </div>
            </div>
            <div className="row row--6">
              <div className="col-lg-12">
                <Slider {...slider}>
                  {gallery.map((item, index) => (
                    <div key={index}>
                      <button
                        className="home-gallery-slider__item"
                        onClick={() => setModalImage(item)}
                      >
                        <span
                          className="home-gallery-slider__image"
                          style={{ backgroundImage: `url('${item}')` }}
                        />
                      </button>
                    </div>
                  ))}
                </Slider>
                <div className="gardens-content__cta d-flex justify-content-center">
                  <Link
                    className="button button--giga"
                    to="/mieszkania/"
                    state={{
                      apartments: {
                        floors: [4],
                      },
                    }}
                  >
                    Wybierz mieszkanie
                  </Link>
                </div>
              </div>
            </div>
            <div className="gardens-content__decor gardens-content__decor--1">
              <motion.div
                style={{
                  x: decor1_x,
                  y: decor1_y,
                  rotate: decor1_rotate,
                  opacity: decor1_opacity,
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={require("assets/images/gardens/decor-1.svg").default}
                    alt=""
                  />
                </div>
              </motion.div>
              <span ref={decor1} />
            </div>
            <div className="gardens-content__decor gardens-content__decor--2">
              <motion.div
                style={{
                  x: decor2_x,
                  y: decor2_y,
                  rotate: decor2_rotate,
                  opacity: decor2_opacity,
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={require("assets/images/gardens/decor-2.svg").default}
                    alt=""
                  />
                </div>
              </motion.div>
              <span ref={decor2} />
            </div>
            <div className="gardens-content__decor gardens-content__decor--3">
              <motion.div style={{ x: decor3_x, opacity: decor3_opacity }}>
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={require("assets/images/gardens/decor-3.svg").default}
                    alt=""
                  />
                </div>
              </motion.div>
              <span ref={decor3} />
            </div>
            <div className="gardens-content__decor gardens-content__decor--4">
              <motion.div
                style={{
                  x: decor4_x,
                  rotate: decor4_rotate,
                  opacity: decor4_opacity,
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={require("assets/images/gardens/decor-4.svg").default}
                    alt=""
                  />
                </div>
              </motion.div>
              <span ref={decor4} />
            </div>
            <div className="gardens-content__decor gardens-content__decor--5">
              <motion.div
                style={{
                  x: decor5_x,
                  y: decor5_y,
                  rotate: decor5_rotate,
                  opacity: decor5_opacity,
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={require("assets/images/gardens/decor-5.svg").default}
                    alt=""
                  />
                </div>
              </motion.div>
              <span ref={decor5} />
            </div>
            <div className="gardens-content__decor gardens-content__decor--6">
              <motion.div
                style={{
                  y: decor6_y,
                  scale: decor6_scale,
                  opacity: decor6_opacity,
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={require("assets/images/gardens/decor-6.svg").default}
                    alt=""
                  />
                </div>
              </motion.div>
              <span ref={decor6} />
            </div>
          </div>
        </div>
      </section>
      <ModalImage image={modalImage} setImage={setModalImage} />
    </>
  )
}

export default Content
