import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Apartments = () => {
  return (
    <section className="gardens-apartments">
      <div className="container">
        <div className="row flex-column-reverse flex-xl-row">
          <div className="col-xl-6">
            <div className="gardens-apartments__content">
              <h2>
                Mieszkać blisko.
                <br />
                <span>
                  Z własnym ogrodem
                  <br />
                  na dachu
                </span>
              </h2>
              <p>
                Wyjątkowe apartamenty łączą funkcjonalny rozkład pomieszczeń z
                komfortem i bliskością natury. Stworzyliśmy przestrzeń, która
                pozwoli Ci cieszyć się panoramicznym widokiem miasta i
                jednocześnie odpocząć w zaciszu własnego zielonego azylu.
              </p>
              <div className="gardens-apartments__cta d-flex justify-content-start">
                <Link
                  className="button button--giga"
                  to="/mieszkania/"
                  state={{
                    apartments: {
                      floors: [4],
                    },
                  }}
                >
                  WYBIERZ MIESZKANIE
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-6 d-xl-flex justify-content-xl-end">
            <div className="gardens-apartments__image">
              <img
                src={
                  require("assets/images/gardens/apartments-image.jpg").default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Apartments
